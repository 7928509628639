import React from 'react';
import FallbackImage from './FallbackImage';

const CategoryCard = (props) => {
  const categories = [
    {
      id: 3,
      title: 'Better Skin',
      image: require('../assets/images/logo/Conditioner.png')
    },
    {
      id: 4,
      title: 'Ketchup',
      image: require('../assets/images/logo/Ketchup.png')
    },
    {
      id: 5,
      title: 'Ketchup',
      image: require('../assets/images/logo/Lotion.png')
    },

    {
      id: 2,
      title: 'Beaute Cafe',
      image: require('../assets/images/logo/Cafe1.png')
    },
    {
      id: 6,
      title: 'Facd Mash',
      image: require('../assets/images/logo/Mask.png')
    },
    {
      id: 7,
      title: 'Shampoo',
      image: require('../assets/images/logo/Shampoo.png')
    },
    {
      id: 8,
      title: 'Sleeping Cream',
      image: require('../assets/images/logo/Sleeping Cream.png')
    },
    {
      id: 9,
      title: 'Snail Wish',
      image: require('../assets/images/logo/Snail.png')
    },
    {
      id: 10,
      title: 'Sunscreen',
      image: require('../assets/images/logo/Sunscreen.png')
    },
    {
      id: 1,
      title: 'Better Skin',
      image: require('../assets/images/logo/BS Soap1.png')
    },
    {
      id: 11,
      title: 'Suppliment',
      image: require('../assets/images/logo/Suppliment.png')
    },
    {
      id: 13,
      title: 'Tooth Balance',
      image: require('../assets/images/logo/Ts.png')
    },
    {
      id: 12,
      title: 'Toner',
      image: require('../assets/images/logo/Toner.png')
    }
  ];

  return (
    <div className="container mt-5">
      <div className="logo-container">
        {categories &&
          categories.map((category) => (
            <div className="" key={category.id}>
              <FallbackImage
                path={category.image}
                title={category.title}
                className="img-logo"
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryCard;
