import React, { useEffect, useState } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';
// import Navigation from '../components/Navigation';
import Loading from '../components/Loading';
import Icon from '../common/Icon';
// import { Card, Image } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import FallbackImage from '../components/FallbackImage'; // require
import * as dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { postedAgoDate } from '../utils';

// var moment = require('moment');

const PostDetailPage = (props) => {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  let { postId } = useParams();

  const [postedInterval, setPostedInterval] = useState(0);

  const {
    description,
    created_at,
    status,
    images,
    agent_name,
    profile_picture
  } = post;

  const postedDate = dayjs(created_at);

  const currentDate = dayjs(new Date());

  // const intervalDate = (today, postDate) => {
  //     var relativeTime = require('dayjs/plugin/relativeTime')
  //     dayjs.extend(relativeTime)

  //     const day1 = dayjs(postDate.formdata()).fromNow;
  //     console.log("today", day1);

  //     // console.log("dtaa", postDate);
  //     // const pDate = dayjs(postDate);
  //     // console.log("gggg", pDate.format());

  //     // let timeDiff =  today.diff(postDate, 'months');
  //     // let diffType = timeDiff === 0 ? 'day' : today.diff(postDate, 'month') >= 12 ? 'year' : 'month';
  //     // const totalDiff = today.diff(postDate, diffType)
  //     // return totalDiff + ` ${totalDiff > 1 ? diffType + 's' : diffType}`
  //    }

  useEffect(() => {
    const date = postedAgoDate(postedDate);
    setPostedInterval(date);
  }, [currentDate, postedDate]);
  useEffect(() => {
    getPostDetail(postId);
  }, [postId]);

  const getPostDetail = async (postId) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append('post_id', postId);

    await ApiService(url.post_detail, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setPost(response.data);
      } else {
      }
    });
    setLoading(false);
  };

  const postStatus = {
    PUBLISH: <Icon name="globe" />
  };

  return (
    <>
      {/* <Navigation/> */}
      <div
        className="pb-3"
        style={{ marginTop: '5em', minHeight: 'calc(100vh - 7.5em)' }}
      >
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <div className="container post-detailed-page">
            <div className="row">
              <div className="col-lg-8 col-md-12 post-detailed-left-side position-relative">
                <div className="col-lg-12 p-3 d-flex align-items-center justify-content-center animate__animated animate__fadeInUp">
                  {images?.length > 0 && images[0]?.path !== null ? (
                    <FallbackImage
                      className="post-detailed-img"
                      path={images[0]?.path}
                    />
                  ) : (
                    <FallbackImage
                      path={'https://i.ibb.co/WcYXrpt/thumbnail.png'}
                      title=""
                      className="post-detailed-img"
                    />
                  )}
                </div>
                <Link
                  className="position-absolute agent-back-btn"
                  to={{ pathname: '/posts' }}
                >
                  <Icon name="back" />
                </Link>
              </div>
              <div className="col-lg-4 col-md-12 p-4">
                <div className="d-flex">
                  <FallbackImage
                    path={profile_picture}
                    title=""
                    className="product-post-profile"
                  />
                  <div className="ms-3">
                    <h5 className="post-owner-name">{agent_name}</h5>
                    <span
                      className="post-status"
                      title={`Post Status : ${status}`}
                    >
                      {postStatus[status]} {postedInterval}
                    </span>
                  </div>
                </div>
                <p className="mt-3">{description}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PostDetailPage;
