import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import AgentDetailed from '../common/AgentDetailed';
import { DataContext } from '../context/DataContent';

const TopAgentDetailPage = () => {
  const { topAgentList } = useContext(DataContext);

  const [loading, setLoading] = useState(false);
  const [topAgent, setTopAgent] = useState();
  const [post, setPost] = useState([]);
  let { topAgentId } = useParams();

  useEffect(() => {
    getAgentDetail(topAgentId, topAgentList);
    getPostByAgent(topAgent);
  }, [topAgentId, topAgentList, topAgent]);

  const getAgentDetail = (agent_id, agentList) => {
    const isExistAgent = (element) => element.id === parseInt(agent_id);
    const agentInfo = agentList.find(isExistAgent);
    console.log('agent info::', agentInfo);

    if (agentInfo) {
      setTopAgent(agentInfo);
    }
  };

  const getPostByAgent = async (agentInfo) => {
    let formdata = new FormData();
    formdata.append('top_agent_id', agentInfo?.agent_id);
    formdata.append('page', 1);
    formdata.append('row_count', 20);

    await ApiService(url.top_agent_post, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setPost(response.data);
      } else {
      }
    });

    setLoading(false);
  };

  console.log('top agent list::', topAgentList, topAgentId);

  return (
    <>
      {/* <Navigation/> */}
      <div className="pb-3" style={{ marginTop: '4.5em' }}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <AgentDetailed
              agentInfo={topAgent?.agent}
              postList={post}
              backRoute="/top-agents"
              agentType="top-agent"
            />
          </>
        )}
      </div>
    </>
  );
};

export default TopAgentDetailPage;
