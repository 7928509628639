import React from 'react';
import { Link } from 'react-router-dom';

const HeaderTitle = (props) => {
  const { title, path } = props;

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-center trendingDiv">
        {/* <p style={{ visibility:'hidden'}}>View More its</p> */}
        <div>
          <h4 className="text-center">{title}</h4>
          <p className="vertical-dotted-line"></p>
        </div>
      </div>
      <div className="d-flex flex-row-reverse">
        <Link
          to={`/${path}`}
          style={{ visibility: path === '' ? 'hidden' : 'visible' }}
        >
          <button className="viewmore-button ms-auto">
            {' '}
            View More
            <div className="icon">
              <svg
                height="24"
                width="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M0 0h24v24H0z" fill="none"></path>
                <path
                  d="M16.172 11l-5.364-5.364 1.414-1.414L20 12l-7.778 7.778-1.414-1.414L16.172 13H4v-2z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
          </button>
        </Link>
      </div>
    </div>
  );
};
export default HeaderTitle;
