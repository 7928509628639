import React, { useEffect, useState } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import { useParams } from 'react-router-dom';
import AgentDetailed from '../common/AgentDetailed';

const AgentDetailPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [agent, setAgent] = useState({});
  const [post, setPost] = useState([]);
  let { agentId } = useParams();

  useEffect(() => {
    getAgentDetail(agentId);
    getPostByAgent(agentId);
  }, [agentId]);

  const getAgentDetail = async (agentId) => {
    setLoading(true);
    let formdata = new FormData();
    formdata.append('agent_id', agentId);

    await ApiService(url.agent_detail, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setAgent(response.data);
      } else {
      }
    });
  };

  const getPostByAgent = async (agentId) => {
    let formdata = new FormData();
    formdata.append('agent_id', agentId);
    formdata.append('page', 1);
    formdata.append('row_count', 20);

    await ApiService(url.agent_post, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setPost(response.data);
      } else {
      }
    });

    setLoading(false);
  };

  return (
    <>
      {/* <Navigation/> */}
      <div className="pb-3" style={{ marginTop: '4.5em' }}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <>
            <AgentDetailed
              agentInfo={agent}
              postList={post}
              backRoute="/agents"
            />
          </>
        )}
      </div>
    </>
  );
};

export default AgentDetailPage;
