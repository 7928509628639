import FallbackImage from '../components/FallbackImage';
import { Link } from 'react-router-dom';

const AgentCard = ({ agentInfo }) => {
  const { agentGroup, agent_id, name } = agentInfo;

  // const groupColor = {
  //     "A" : "#173F5F",
  //     "B" : "#20639B",
  //     "C" : "#3CAEA3",
  //     "D" : "#F6D55C",
  //     "E" : "#ED553B",
  //     "F" : "#FFD9C9"
  // }

  return (
    <Link
      className="agent-card-container col-12 col-lg-3 col-md-6 col-sm-6 gx-5 gy-4 g-lg-2 col-md-6 col-sm-6 animate__animated animate__fadeInUp"
      to={{ pathname: `/agents/${agent_id}` }}
    >
      <div className="card pt-5 pb-3 d-flex flex-shrink-1 align-items-center justify-content-center">
        <div className="agent-card--profile-container position-relative">
          <FallbackImage
            path={agentInfo?.profile_picture}
            title="..."
            className="agent-profile"
          />
          {/* <div className="group-color-circle position-absolute" style={{ backgroundColor: groupColor[agentGroup] }} /> */}
        </div>
        <div className="card-body agent-card-body">
          <h5
            className="mt-3 text-center fs-md text-truncate"
            style={{ maxWidth: '250px' }}
          >
            {name}
          </h5>
          <p className="agent-card-info text-sm-center">{agent_id}</p>
          <p className="agent-card-info text-sm-center ">
            Group - <span className="fw-bold">{agentGroup}</span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default AgentCard;
