import React from 'react'
import { FaFacebookSquare, FaPhoneSquareAlt, FaCrown } from "react-icons/fa";
import { AiFillTags } from "react-icons/ai";
import { HiOutlineGlobe } from "react-icons/hi";
import { TiArrowBack } from "react-icons/ti";

const Icon = ({ name, link }) => {

    const iconList = {
        "facebook" : <a href={link}><FaFacebookSquare size="28px" color="#3B5998" /></a>,
        "phone"    : <a href={`tel:${link}`}><FaPhoneSquareAlt size="28px" color="#13A301" /></a>,
        "crown"    : <FaCrown size="20px" color="var(--top-agent-budget)" />,
        "tag"      : <AiFillTags size="20px" />,
        'globe'    : <HiOutlineGlobe />,
        'back'     : <TiArrowBack size="25px" />
    }

  return iconList[name];
}

export default Icon