import React from 'react';
import Pagination from './Pagination';

const FallbackPaginator = (props) => {
  const { data, pageIndex, setPageIndex, paginate } = props;

  return (
    <>
      {data && (
        <div>
          {paginate && paginate.total_page > 1 && (
            <Pagination
              rowCount={paginate.row_count}
              totalRecordCount={paginate.total_records_count}
              pageIndex={pageIndex}
              setPageIndex={setPageIndex}
            />
          )}
          {paginate && paginate.total_records_count === 0 && (
            <div className="d-flex justify-content-center">
              <p>No Records Found</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default FallbackPaginator;
