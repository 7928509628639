import React from 'react';
import { Link } from 'react-router-dom';
import FallbackImage from '../FallbackImage';
import ProductPrice from '../../common/ProductPrice';

const Product = (props) => {
  const { data } = props;

  return (
    <>
      <div className="container p-6">
        <div className="row product-list-container">
          {data &&
            data.map((d) => (
              <div
                className={`col-lg-3 col-md-6 col-sm-6 col-12 g-3 g-lg-4 img-container d-flex justify-content-center align-items-center p-3 animate__animated animate__fadeInUp`}
                key={d.id}
              >
                <Link
                  to={{ pathname: `/products/${d.id}` }}
                  style={{ width: '100%' }}
                >
                  <div className="product-card-container">
                    <div className="product-image-container">
                      <FallbackImage
                        path={d?.thumbnail?.path}
                        title={d.name}
                        className="product-image"
                      />
                    </div>
                    <div className="product-image-info-container">
                      <p className="productTitle">{d.name}</p>
                      <small className="productAmount">
                        {ProductPrice(d.price)} MMK
                      </small>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

Product.defaultProps = {
  data: []
};

export default Product;
