import React, { useState, useEffect } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';
import FallbackImage from '../components/FallbackImage';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../common/BreadCrumb';
import Loading from '../components/Loading';
import ProductPrice from '../common/ProductPrice';
// import { useCallback } from 'react';
// import BackButton from '../common/BackButton';
import ProductPost from '../components/ProductPost';

const ProductDetailPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});
  const [post, setPost] = useState({});
  const [review, setReview] = useState({});
  const [attribute, setAttribute] = useState({});
  const [selectedInfoType, setSelectedInfoType] = useState('Description');
  let { productId } = useParams();

  // const [ breadCrumbList, setBreadCrumbList ] = useState(navigateRouteList);

  useEffect(() => {
    getProductDetail(productId);

    getProductAttribute(productId);

    getProductReview(productId);

    getPostByProduct(productId);
  }, [productId]);

  const getProductDetail = async (productId) => {
    setLoading(true);

    let formdata = new FormData();

    formdata.append('product_id', productId);

    await ApiService(url.product_detail, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setProduct(response.data);
      } else {
      }
    });
  };

  // const modifiedRouteList = useCallback((productInfo) => {

  //   setBreadCrumbList((prevInfo) => {

  //     const newRoute = {
  //       path: `/products/${productId}`,
  //       name: productInfo?.name
  //     };

  //     const routeIndex = prevInfo?.findIndex((element) => element === newRoute);

  //     console.log("exist bread crumb :", routeIndex, prevInfo );

  //     if(routeIndex < 0 && productInfo?.name !== undefined) {

  //      prevInfo.push(newRoute);

  //      return prevInfo;

  //     }

  //     return navigateRouteList;

  //   })
  // }, [productId])

  // useEffect(() => {

  //   modifiedRouteList(product);

  // }, [product, modifiedRouteList]);

  const getProductReview = async (productId) => {
    let formdata = new FormData();
    formdata.append('product_id', productId);
    formdata.append('page', 1);
    formdata.append('row_count', 20);

    await ApiService(url.product_review, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setReview(response.data);
      } else {
      }
    });
  };

  const getProductAttribute = async (productId) => {
    let formdata = new FormData();
    formdata.append('product_id', productId);
    formdata.append('page', 1);
    formdata.append('row_count', 20);

    await ApiService(url.product_attribute, formdata, 'POST').then(
      (response) => {
        if (response.code === 200) {
          setAttribute(response.data);
        } else {
        }
      }
    );
  };

  const getPostByProduct = async (productId) => {
    let formdata = new FormData();
    formdata.append('product_id', productId);
    formdata.append('page', 1);
    formdata.append('row_count', 20);

    await ApiService(url.product_post, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setPost(response.data);
      } else {
      }
    });
    setLoading(false);
  };

  const { thumbnail, name, price, type } = product;

  const breadCrumbList = [
    {
      path: '/',
      name: 'Home'
    },
    {
      path: '/products',
      name: 'Products'
    },
    {
      path: `/products/${productId}`,
      name: name
    }
  ];

  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: 'calc(100vh - 70px)' }}
    >
      <Loading />
    </div>
  ) : (
    <>
      <div
        className="container"
        style={{ minHeight: 'calc(100vh - 8em)', marginTop: '5em' }}
      >
        <div className="bread-crumb-container mb-4 d-flex align-items-center">
          <BreadCrumb routeList={breadCrumbList} />
          {/* <BackButton route={"/products"} name="Products" /> */}
        </div>
        <div className="container">
          <div className="d-flex flex-wrap justify-content-between g-3 g-lg-2">
            <div className="product-detailed--left-side">
              <FallbackImage
                className="product-detailed-img"
                path={thumbnail?.path}
                title={thumbnail?.name}
              />
            </div>
            <div className="product-detailed--right-side">
              <h4>{name}</h4>
              <div className="mt-2 product-detailed--price">
                {ProductPrice(price)} MMK
              </div>
              <div className="mt-4">
                <span className="text-gray">Category:</span>{' '}
                <span className="product-category-type">{type}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom mt-4">
          {['Description', 'Posts'].map(
            (productSpecType, productSpecTypeIndex) => (
              <button
                key={productSpecTypeIndex}
                className={
                  selectedInfoType === productSpecType
                    ? 'product-specification-tab active'
                    : 'product-specification-tab'
                }
                onClick={() => {
                  setSelectedInfoType(productSpecType);
                }}
              >
                {productSpecType}
              </button>
            )
          )}
        </div>
        <div>
          {selectedInfoType === 'Description' ? (
            attribute?.length !== undefined ? (
              <div style={{ minHeight: '40vh' }}>
                {attribute?.map((attributeInfo, attributeIndex) => (
                  <div className="container mt-4" key={attributeIndex}>
                    <p className="fw-bold">{attributeInfo.name}</p>
                    <p>{attributeInfo.content}</p>
                  </div>
                ))}
              </div>
            ) : (
              <EmptyProductInfo text="There is no related description." />
            )
          ) : selectedInfoType === 'Reviews' ? (
            review?.length !== undefined ? (
              <div style={{ minHeight: '40vh' }}>
                {review?.map((productReview, productReviewIndex) => (
                  <ProductReview
                    info={productReview}
                    key={productReviewIndex}
                  />
                ))}
              </div>
            ) : (
              <EmptyProductInfo text="There is no reviews yet." />
            )
          ) : selectedInfoType === 'Posts' ? (
            post?.length !== undefined ? (
              <div className="container mt-4" style={{ minHeight: '40vh' }}>
                {post?.map((productPost, productPostIndex) => (
                  <ProductPost post={productPost} key={productPostIndex} />
                ))}
              </div>
            ) : (
              <EmptyProductInfo text="There is no post yet." />
            )
          ) : null}
        </div>
        <div className="product-detail-page-related-products-list"></div>
      </div>
    </>
  );
};

const EmptyProductInfo = ({ text }) => {
  return (
    <div
      className="container d-flex justify-content-center align-items-center text-align-center"
      style={{ height: '40vh' }}
    >
      {text}
    </div>
  );
};

const ProductReview = () => {
  return <div></div>;
};

export default ProductDetailPage;
