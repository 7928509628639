import React, { useState, useEffect } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import AgentHeader from '../common/AgentHeader';
import TopAgent from '../components/TopAgent';

const TopAgentPage = () => {
  const [topAgent, setTopAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const pageIndex = 1;
  // const [paginate, setPaginate ] = useState();

  useEffect(() => {
    getAgentList(pageIndex);
  }, [pageIndex]);

  const getAgentList = async (currentPage) => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append('page', currentPage);
    formdata.append('row_count', '24');

    await ApiService(url.top_agent, formdata, 'POST').then((response) => {
      setLoading(false);
      if (response.code === 200) {
        setTopAgent(response.data);
      } else {
      }
    });
  };

  return (
    <>
      <div className="pb-3">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <div className="" style={{ marginTop: '5em' }}>
            <AgentHeader
              title="Meet Our Top Agents"
              description="Agent user can analyze the detail of each sale and purchase operation easily."
            />
            <div className="py-3">
              <TopAgent data={topAgent} />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default TopAgentPage;
