import React from 'react'

const DropDown = ({selectedValue, dropDownList, onChoose}) => {

    const handleChoose = (dropDownInfo) => {
        onChoose(dropDownInfo);
    }

  return (
    <div className="dropdown .dropdown-menu{ max-height:150px; overflow:auto; }">
        <button className="btn dropdown-toggle product-brand-button" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedValue}
        </button>
        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
            {
                dropDownList && dropDownList.map((info, index) => 
                    <li className="dropdown-item" key={index}>
                        <button className='brand-category-btn' onClick={() => handleChoose(info)}>
                            {info.name}
                        </button>
                    </li>
                )
            }
        </ul>
    </div>
  )
}

export default DropDown