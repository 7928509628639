import React from 'react';
import '../assets/css/style.css';
import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import google from '../assets/images/google-play-badge.png';
import appStore from '../assets/images/App_Store_Badge.png';
import appCenter from '../assets/images/appcenterms-ar21.png';

const Footer = () => {
  return (
    <div
      className="fb-footer-container px-5 mt-3 align-items-center"
      style={{ backgroundColor: 'rgba(0,0,0,.03)' }}
    >
      <div className="box">
        <div className="footer-row mt-5">
          <div className="footer-column">
            <Link to={{ pathname: '/' }}>
              <Image
                src={require('../assets/images/logo.png')}
                fluid={true}
                className="mb-4"
              />
            </Link>
            <div className="footer-logo">
              <a
                className=""
                href="https://www.facebook.com/FocusBeautyHeadOffice/"
                target="_blank"
                rel="noreferrer"
              >
                <Player
                  src={
                    'https://assets8.lottiefiles.com/packages/lf20_7akxvutj.json'
                  }
                  className="footer-social-box"
                  loop
                  autoplay
                />
              </a>
              <a
                href="https://www.facebook.com/FocusBeautyHeadOffice/"
                target="_blank"
                rel="noreferrer"
              >
                <Player
                  src={
                    'https://assets6.lottiefiles.com/packages/lf20_2ks3pjua.json'
                  }
                  className="footer-social-box"
                  loop
                  autoplay
                />
              </a>
              <a
                href="https://www.facebook.com/FocusBeautyHeadOffice/"
                target="_blank"
                rel="noreferrer"
              >
                <Player
                  src={
                    'https://lottie.host/e262f01c-fad0-4189-bdcc-e973c9235b8d/bH3f7owfhJ.json'
                  }
                  className="footer-social-box twitter"
                  loop
                  autoplay
                />
              </a>
            </div>
          </div>
          <div className="footer-column">
            <h5>Our Company</h5>
            <Link to={'/'}>Home</Link>
            <Link to={'/products'}>Products</Link>
            <Link to={'/agents'}>Agents</Link>
            <Link to={'/top-agents'}>Top Agents</Link>
            <Link to={'/posts'}>Posts</Link>
          </div>
          <div className="footer-column">
            <h5>Our Products</h5>
            <a
              href={'https://www.facebook.com/NewSkiin'}
              target="_blank"
              rel="noreferrer"
            >
              New Skiin
            </a>
            <a
              href={'https://www.facebook.com/SnailWishWhiteningCream'}
              target="_blank"
              rel="noreferrer"
            >
              Snail Wish Whitening Cream
            </a>
            <a
              href={'https://www.facebook.com/BeauteCafeByFocusBeauty'}
              target="_blank"
              rel="noreferrer"
            >
              Beauty Cafe
            </a>
            <a
              href={'https://www.facebook.com/KetchupByFocusBeauty'}
              target="_blank"
              rel="noreferrer"
            >
              Ketchup
            </a>
          </div>
          <div className="footer-column">
            <h5>Download Our App</h5>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=mm.com.xan.focusbeauty&hl=en&gl=US&pli=1"
            >
              <img
                src={google}
                alt="Google Play Store"
                className="footer-img-container"
              />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://apps.apple.com/mm/app/focus-beauty/id1530683002"
            >
              <img src={appStore} alt="App Store" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://install.appcenter.ms/orgs/zote-family/apps/focus-beauty-2/distribution_groups/client"
            >
              <img
                src={appCenter}
                alt="App Center"
                className="footer-img-container"
              />
            </a>
          </div>
        </div>
      </div>
      <p className="footer-separator mt-2"></p>
      <div className="footer-bottom mt-2">
        <div className="fb-footer-text copyright">
          © Copyright {new Date().getFullYear()}. All Rights Reserved
        </div>
        {/* <p className='fb-footer-text d-flex justify-content-center align-items-center p-2 mb-0'>v - 0.0.2</p> */}
        <div className="d-flex justify-content-center">
          Powered by
          <Player
            src={'https://assets5.lottiefiles.com/packages/lf20_npyyolgp.json'}
            className="player"
            loop
            autoplay
            style={{ width: '20px', height: '20px' }}
          />
          Zote by Focus Innovation Co.
        </div>
      </div>
    </div>
  );
};

export default Footer;
