import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { ImSearch } from 'react-icons/im';
import { debounce } from 'lodash';

export const NavSearchBox = (props) => {
  const { searchKeyword, setSearchKeyword, name } = props;
  const [search, setSearch] = useState(false);
  const handleChange = (e) => {
    setSearch(true);
    setSearchKeyword(e.target.value);
  };

  const clickClose = () => {
    setSearch(false);
    setSearchKeyword('');
  };

  const debounceHandle = debounce(handleChange, 500);

  return (
    <>
      {/* <div className="d-flex search-box-btn" >
        {
            search ?
            <div className="searchBox-container">
                <ImSearch />
                <input className='searchBox searchBoxLong' defaultValue={searchKeyword} onChange={ debounceHandle }/>
            </div>
            :
            <div className="searchBox-container" onClick={debounceHandle}>
                <ImSearch />
                <input className='searchBox' onChange={clickClose} defaultValue={""}/>
            </div>
        }
        </div> */}
      <Form className="d-flex search-box-btn">
        {search ? (
          <div className="searchBox-container">
            <ImSearch />
            <Form.Control
              type="search"
              placeholder={`Search ${name}`}
              aria-label={`Search`}
              className="searchBox searchBoxLong"
              onChange={debounceHandle}
              defaultValue={searchKeyword}
            />
          </div>
        ) : (
          <div className="searchBox-container" onClick={debounceHandle}>
            <ImSearch />
            <Form.Control
              type="search"
              placeholder={`Search ${name}`}
              aria-label="Search"
              className="searchBox"
              onChange={clickClose}
              defaultValue=""
            />
          </div>
        )}
      </Form>
    </>
  );
};
