import React from 'react';
import { Routes, Route } from "react-router-dom";
import ProductPage from '../pages/ProductPage';
import Home from '../pages/Home';
import AgentPage from '../pages/AgentPage';
import PostPage from '../pages/PostPage';
import PostDetailPage from '../pages/PostDetailPage';
import AgentDetailPage from '../pages/AgentDetailPage';
import ProductDetailPage from '../pages/ProductDetailPage';
import About from '../pages/About';
import Tag from '../components/Tag';
import TopAgentPage from '../pages/TopAgentPage.';
import PageContainer from '../components/PageContainer';
import TopAgentDetailPage from '../pages/TopAgentDetailPage';
import NotFound from '../components/NotFound';
import ProductFlyer from '../pages/ProductFlyer';

const Router = (props) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<PageContainer />} >
          <Route path="/" index element={<Home />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/products/:productId" element={<ProductDetailPage />} />
          <Route path="/agents" element={<AgentPage />} />
          <Route path="/top-agents" element= {<TopAgentPage />} />
          <Route path="/posts" element={<PostPage />} />
          <Route path="/posts/:postId" element={<PostDetailPage />} />
          <Route path="/tags/:tagId/posts" element={<Tag /> } />
          <Route path="/agents/:agentId" element={<AgentDetailPage />} />
          <Route path="/top-agents/:topAgentId" element={<TopAgentDetailPage />} />
          <Route path="/about" element={<About />} />
        </Route>
        <Route path='*' element={<NotFound />}></Route>
        <Route path="/not-found" element={<NotFound />}></Route>
        <Route path="/products/:productId/flyer" element={<ProductFlyer />} />
        {/* <Route path="/products/:productId/flyer?region=:region" element={<ProductFlyer />} /> */}
      </Routes>
    </>
  );
}

export default Router;