import React, { useState, useEffect } from 'react';
import CategoryCard from '../components/CategoryCard';
import PostHorizontal from '../components/PostHorizontal';
import HeaderTitle from '../components/HeaderTitle';
import { ApiService } from '../network/service';
import url from '../network/url';
import CarouselSlider from '../common/CarouselSlider';
import TopAgent from '../components/TopAgent';
import TrendingProduct from '../components/product/TrendingProduct';
import Loading from '../components/Loading';
import { AppDownloadBanner } from '../components/AppDownloadBanner';

const Home = (props) => {
  const [product, setProduct] = useState([]);
  const [agent, setAgent] = useState([]);
  const [post, setPost] = useState([]);
  const [productAdList, setProductAdList] = useState([]);
  const [loading, setLoading] = useState('false');

  useEffect(() => {
    getProductList();
    getAgentList();
    getPostList();
    getAdList();
  }, []);

  const getAdList = async () => {
    let formdata = new FormData();

    formdata.append('page', '1');
    formdata.append('row_count', '10');

    await ApiService(url.product_ad, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setProductAdList(response.data);
      } else {
      }
    });
  };

  const getProductList = async () => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append('page', '1');
    formdata.append('row_count', '4');

    await ApiService(url.trending_product, formdata, 'POST').then(
      (response) => {
        setLoading(false);
        if (response.code === 200) {
          setProduct(response.data);
        } else {
        }
      }
    );
  };

  const getAgentList = async () => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append('page', '1');
    formdata.append('row_count', '6');
    await ApiService(url.top_agent, formdata, 'POST').then((response) => {
      setLoading(false);
      if (response.code === 200) {
        setAgent(response.data);
      } else {
      }
    });
  };

  const getPostList = async () => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append('page', '1');
    formdata.append('row_count', '3');
    await ApiService(url.post, formdata, 'POST').then((response) => {
      setLoading(false);
      if (response.code === 200) {
        setPost(response.data);
      } else {
      }
    });
  };

  return (
    <>
      <CarouselSlider sliderInfo={productAdList} />

      <CategoryCard />

      <HeaderTitle title="Trending Product" path="" />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      ) : (
        <TrendingProduct data={product} />
      )}

      <HeaderTitle title="Top Agent" path="top-agents" />
      {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      ) : (
        <TopAgent data={agent} />
      )}

      {/* <div className='p-lg-5 p-md-3 p-sm-3'>
            <TopAgent data={agent}/>
        </div> */}

      {/* <HeaderTitle title="Review"  path="" />

        <div className='review-bg-color p-lg-5 p-md-3 p-sm-3 p-3'>
            <Review/>
        </div> */}

      <HeaderTitle title="Post" path="posts" />
      {loading ? (
        // <Player src={'https://assets5.lottiefiles.com/packages/lf20_sjcbakkb.json'} className='player' style={{ height:'80px'}} loop autoplay/>
        <div className="d-flex justify-content-center align-items-center">
          <Loading />
        </div>
      ) : (
        <PostHorizontal data={post} />
      )}
      <AppDownloadBanner />
    </>
  );
};

export default Home;
