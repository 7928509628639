import React from 'react';
import FallbackImage from '../components/FallbackImage';
import { Link } from 'react-router-dom';

const TopAgentCard = ({ agentInfo }) => {
  const { agentGroup, agent_id, name } = agentInfo?.agent;
  const { level, id } = agentInfo;

  return (
    <div className="position-relative col-10 col-lg-4 col-md-3 gx-md-5 gx-sm-5 g-3 g-lg-5 col-md-6 col-sm-6 mt-5">
      <Link to={{ pathname: `/top-agents/${id}` }}>
        <div>
          <div className="position-absolute d-flex align-items-center justify-content-center agent-budge-container">
            <div className="agent-budge-inner-circle fw-bold d-flex align-items-center justify-content-center">
              {level}
            </div>
          </div>
          <span className="position-absolute agent-right-budge" />
          <span className="position-absolute agent-left-budge" />
          <div className="top-agent-info-container">
            <div className="top-agent-inner-info-box d-flex justify-content-between">
              <div className="d-flex flex-column justify-content-between top-agent-inner-info-container">
                <div>
                  <h4 className="top-agent-name" title={name}>
                    {name}
                  </h4>
                  <span className="top-agent-info">{agent_id}</span>
                </div>
                <p className="top-agent-group-name">Group - {agentGroup}</p>
              </div>
              <FallbackImage
                path={agentInfo?.agent?.profile_picture}
                title=""
                className="top-agent-img"
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default TopAgentCard;
