import React from 'react';

const FallbackImage = (props) => {
  const { path, title, className } = props;
  return (
    <div className="fallback-image-container">
      {path ? (
        <img
          src={path}
          alt={title}
          className={`fallback-image ${className}`}
          loading="lazy"
          width="100%"
        />
      ) : (
        <img
          src="https://i.ibb.co/WcYXrpt/thumbnail.png"
          alt={title}
          className={`fallback-image ${className}`}
          loading="lazy"
          width="100%"
        />
      )}
    </div>
  );
};

export default FallbackImage;
