import React from 'react'
import FallbackImage from '../components/FallbackImage';
import { Link } from 'react-router-dom';
// import { FaFacebookSquare, FaPhoneSquareAlt } from "react-icons/fa";
import ProductPost from '../components/ProductPost';
import Icon from './Icon';

const AgentDetailed = ({agentInfo, postList, backRoute, agentType}) => {
  return (
    <div className="container p-3" style={{ minHeight: 'calc(100vh - 175px)' }}>
      <div className='position-relative w-full'>
          <Link className="position-absolute agent-back-btn" to={{ pathname: backRoute }}>
              <Icon name="back" />
          </Link>
          <div className='agent-detailed--cover'/>
          <div className='agent-detailed--info-container position-absolute p-4 d-flex justify-content-between align-items-center'>
              <div className='d-flex align-items-center'>
                    <div className="position-relative">
                        <FallbackImage path={agentInfo?.profile_picture} title="" className="agent-detailed-profile" />
                        {
                            agentType === 'top-agent' && (
                                <span className="position-absolute top-agent-crown">
                                    <Icon name="crown" />
                                </span>
                            )
                        }
                       
                        
                    </div>
                  
                  <div className='flex-grow-1 ms-3'>
                      <h4>{agentInfo?.name}</h4>
                      <p>{agentInfo?.agent_id}</p>
                  </div>
              </div>
              <div>
                  {
                      agentInfo?.fb_url && <Icon name="facebook" link={agentInfo?.fb_url} />
                  }
                  {
                      agentInfo?.phone_no && <Icon name="phone" link={agentInfo?.phone_no} />
                  }
              </div>
          </div>
      </div>
      <div className="row mt-5 py-5 px-4  g-3 g-lg-5">
          <div className='agent-detailed-page--info col col-12 col-lg-4 col-md-4 col-sm-12 p-4 ml-0'>
              <h4>More Details</h4>
              <AgentDetailedInfo title="Email : " value={agentInfo?.email} />
              <AgentDetailedInfo title="Address : " value={`${agentInfo?.township} Township, ${agentInfo?.city} City, ${agentInfo?.division} Division, ${agentInfo?.country} Country`}  />
              <AgentDetailedInfo title="Agent Group : " value={agentInfo?.agentGroup} />
          </div>
          {
              postList.length > 0 && (
                  <div className="agent-detailed-post-list row mt-3  p-0">
                      {
                          postList.map((postInfo, postIndex) =>
                              <ProductPost post={postInfo} key={postIndex} />
                          )
                      }
                  </div>
              )
          }
          {
              postList.length === 0 && <div className="col col-lg-8 col-md-8 col-sm-12 d-flex align-items-center justify-content-center">There is no posts yet.</div>
          }

      </div>  
    </div>
  )
}

const AgentDetailedInfo = ({ title, value }) => {
  return(
      <div className='my-3'>
          <div className='agent-detailed-title'>
              {title}
          </div>
          <div className='agent-detailed-value'>{value ? value : "-"}</div>
      </div>
  )
}

export default AgentDetailed