import React from 'react';
import FallbackImage from '../FallbackImage';
import ProductPrice from '../../common/ProductPrice';
import { Link } from 'react-router-dom';

const TrendingProduct = (props) => {
  const { data } = props;

  return (
    <>
      <div className="container mb-2">
        <div className="row">
          {data.map((d) => (
            <div
              className={`mb-0 col-lg-3 col-md-6 col-sm-6 col-12 g-3 g-lg-4 img-container d-flex justify-content-center align-items-center animate__animated animate__fadeInUp`}
              key={d.id}
            >
              <Link
                to={{ pathname: `/products/${d.id}` }}
                style={{ width: '100%' }}
              >
                <div className="product-card-container">
                  <div className="product-image-container">
                    <FallbackImage
                      path={d.path}
                      title={d.name}
                      className="tranding-product"
                    />
                  </div>
                  <div className="product-image-info-container">
                    <p className="productTitle">{d.name}</p>
                    {/* <p className='productOrder'>Product Sold - {d.sort_order}</p> */}
                    <small className="productAmount">
                      {ProductPrice(d.price)} MMK
                    </small>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
TrendingProduct.defaultProps = {
  data: []
};
export default TrendingProduct;
