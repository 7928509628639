import React, { createContext, Children, useState, useEffect } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';


export const DataContext = createContext();

export const DataProvider = (props) => {

    let children = Children.toArray(props.children);

    const pageIndex = 1;
    const [ topAgentList, setTopAgentList ] = useState([]);
    const [ breadCrumbList, setBreadCrumbList ] = useState([]);

    useEffect(() => {
        getAgentList(pageIndex);
    }, [pageIndex] )



    const getAgentList =  async ( currentPage ) => {

        let formdata = new FormData();
  
          formdata.append('page',currentPage);
          formdata.append('row_count','24');
  
          await ApiService(url.top_agent,formdata,'POST').then((response) => {  
              if(response.code === 200){
                setTopAgentList(response.data);
              }else{
  
              }
        })
        
      }

    return (
        <DataContext.Provider value={{ topAgentList, breadCrumbList, setBreadCrumbList }}>
          {children}
        </DataContext.Provider>
      );
}