import React, { useState, useEffect } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import ProductCategory from '../components/product/ProductCategory';
import { useCallback } from 'react';

const ProductPage = (props) => {
  const [product, setProduct] = useState([]);
  const [productCategories, setProductCategories] = useState([
    { id: 'all_products', name: 'All' }
  ]);
  const [productAdList, setProductAdList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [paginate, setPaginate] = useState();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    getProductLists(pageIndex, searchKeyword);
  }, [pageIndex, searchKeyword]);

  useEffect(() => {
    getAdList();
  }, []);

  const getBrandList = useCallback(async () => {
    setLoading(true);
    let formdata = new FormData();

    formdata.append('page', '1');
    formdata.append('row_count', '16');

    await ApiService(url.brand, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        let categoryList = productCategories;
        response.data.map((element) => categoryList.push(element));
        setProductCategories(categoryList);
      } else {
      }
    });
  }, [productCategories]);

  useEffect(() => {
    getBrandList();
  }, [getBrandList]);

  const getAdList = async () => {
    let formdata = new FormData();

    formdata.append('page', '1');
    formdata.append('row_count', '10');

    await ApiService(url.product_ad, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setProductAdList(response.data);
      } else {
      }
    });

    setLoading(false);
  };

  const getProductLists = async (currentPage, searchName) => {
    setSearchLoading(true);
    let formdata = new FormData();

    formdata.append('page', currentPage);
    formdata.append('row_count', '8');
    formdata.append('keyword', searchName);

    await ApiService(url.product, formdata, 'POST').then((response) => {
      setSearchLoading(false);
      if (response.code === 200) {
        setProduct(response.data);
        setPaginate(response.pagination);
      } else {
      }
    });
  };

  return (
    <>
      {/* <Navigation searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} /> */}
      <div className="pb-3">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <ProductCategory
            product={product}
            productCategories={productCategories}
            productAd={productAdList}
            loading={loading}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
            paginate={paginate}
            setPaginate={setPaginate}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            searchLoading={searchLoading}
            setSearchLoading={setSearchLoading}
          />
        )}
      </div>
    </>
  );
};

export default ProductPage;
