import Router from "./routes/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./index.css";
import { DataProvider } from "./context/DataContent";

function App() {
  return (
    <main>
      <DataProvider>
        <Router />
      </DataProvider>
    </main>
  );
}

export default App;
