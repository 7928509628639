import React from 'react';
import { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import '../assets/css/style.css';
// import { NavSearchBox } from './NavSearchBox';
import { IoClose } from 'react-icons/io5';
import { HiOutlineMenu } from 'react-icons/hi';

const Navigation = (props) => {
  // const { searchKeyword, setSearchKeyword } = props;

  const navInfo = [
    {
      name: 'Home',
      link: '/'
    },
    {
      name: 'Products',
      link: '/products'
    },
    {
      name: 'Agents',
      link: '/agents'
    },
    {
      name: 'Top Agents',
      link: '/top-agents'
    },
    {
      name: 'Posts',
      link: '/posts'
    }
    // {
    //   "name": "About Us",
    //   "link": "/about"
    // }
  ];

  const location = useLocation();
  const { pathname } = location;
  // const splitLocation = pathname.split("/");
  const [changeNav, setChangeNav] = useState(false);
  const [showSideNav, setShowSideNav] = useState(false);

  const changeNavBarColor = () => {
    setChangeNav(window.scrollY >= 20);
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNavBarColor);
  }, []);

  return (
    <>
      <header
        className={`navigation-header${
          changeNav ? ' fixedNav' : ''
        } d-flex justify-content-between`}
      >
        <Link to={{ pathname: '/' }}>
          <Image
            src={require('../assets/images/logo.png')}
            fluid={true}
            className="fb-brand-logo"
          />
        </Link>

        <button
          aria-controls="primary-navigation"
          className="mobile-nav-toggle"
          onClick={() => {
            setShowSideNav((prevInfo) => !prevInfo);
          }}
        >
          {!showSideNav ? (
            <HiOutlineMenu size="26px" />
          ) : (
            <IoClose size="26px" />
          )}
        </button>

        <nav
          id="primary-navigation"
          className={`navigation-container${!showSideNav ? ' hide' : ''}${
            changeNav ? ' fixedNav' : ''
          }`}
        >
          <ul>
            {navInfo.map((nav, index) => (
              <li
                onClick={() => {
                  setShowSideNav((prevInfo) => !prevInfo);
                }}
                key={index}
              >
                <Link
                  to={{ pathname: nav.link }}
                  className={
                    pathname === nav.link
                      ? 'navigation-item active'
                      : 'navigation-item'
                  }
                >
                  {nav.name}
                </Link>
                {pathname === nav.link ? (
                  <div className="active-nav-divider" />
                ) : null}
              </li>
            ))}
          </ul>
        </nav>
      </header>
    </>
  );
};

export default Navigation;
