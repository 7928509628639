import React, { useState, useEffect } from 'react';
import Product from './Product';
import { ApiService } from '../../network/service';
import url from '../../network/url';
import Loading from '../Loading';
import { NavSearchBox } from '../NavSearchBox';
import FallbackPaginator from '../FallbackPaginator';
import CarouselSlider from '../../common/CarouselSlider';
import DropDown from '../../common/DropDown';

const ProductCategory = (props) => {
  const {
    product,
    productCategories,
    productAd,
    pageIndex,
    setPageIndex,
    paginate,
    setPaginate,
    searchKeyword,
    setSearchKeyword,
    searchLoading,
    setSearchLoading
  } = props;

  const [filter, setFilter] = useState([]);

  const [selectedBrand, setSelectedBrand] = useState('All');

  useEffect(() => {
    setFilter(product);
  }, [product]);

  const getBrandDetailProduct = async (brandInfo) => {
    setSearchLoading(true);

    const { id, name } = brandInfo;
    setSelectedBrand(name);

    if (id === 'all_products') {
      let formdata = new FormData();

      formdata.append('page', pageIndex);
      formdata.append('row_count', '8');
      formdata.append('keyword', searchKeyword);

      await ApiService(url.product, formdata, 'POST').then((response) => {
        setSearchLoading(false);
        if (response.code === 200) {
          setFilter(response.data);
          setPaginate(response.pagination);
        } else {
        }
      });
    } else {
      let formdata = new FormData();

      formdata.append('page', pageIndex);
      formdata.append('row_count', '8');
      formdata.append('brand_id', id);

      await ApiService(url.brand_product, formdata, 'POST').then((response) => {
        setSearchLoading(false);
        if (response.code === 200) {
          setFilter(response.data);
          setPaginate(response.pagination);
        } else {
        }
      });
    }
  };

  // const handleDebounceChange = useCallback((searchName) => {
  //     setSearchKeyword(searchName);
  // }, [setSearchKeyword]);

  return (
    <>
      <div className="container" style={{ zIndex: '20', marginTop: '5em' }}>
        <CarouselSlider sliderInfo={productAd} />

        <h3 className="mt-4 text-center">Product List</h3>

        <div className="mt-4 filter-box">
          <div className="d-flex justify-content-center fb-link-search-box d-inline">
            <NavSearchBox
              name="Product"
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </div>
          <div className="d-flex justify-content-center">
            <DropDown
              selectedValue={selectedBrand}
              dropDownList={productCategories}
              onChoose={(chosenValue) => {
                getBrandDetailProduct(chosenValue);
              }}
            />
          </div>
        </div>
        <div className="container p-6 mt-4">
          <div className="row">
            {searchLoading ? (
              <div
                className="position-relative d-flex justify-content-center align-items-center"
                style={{ height: 'calc(100vh - 70px)' }}
              >
                <Loading />
              </div>
            ) : (
              <>
                <Product
                  data={filter}
                  loading={searchLoading}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  paginate={paginate}
                />
              </>
            )}
          </div>
          <div className="container">
            <div className="d-flex justify-content-center my-3">
              <FallbackPaginator
                data={filter}
                loading={searchLoading}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                paginate={paginate}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductCategory;
