import React from 'react';
// import FallbackPaginator from './FallbackPaginator';
// import {ApiService} from '../network/service';
// import url from '../network/url';
// import { useParams } from 'react-router-dom';
import ProductPost from './ProductPost';

const Post = (props) => {
  const {
    post
    // tags,
    // pageIndex,
    // setPageIndex,
    // paginate,
    // setPaginate
  } = props;

  // const { tagId } = useParams();
  // const [filter, setFilter] = useState(post);

  // useEffect(() => {

  //     const { data, pagination } = getTagDetailPost(tagId, pageIndex);

  //     setFilter( data);
  //     setPaginate(pagination);

  // }, [tagId, pageIndex, setPaginate])

  // const getTagDetailPost =  async (tagId, currentPage) => {

  //     let formdata = new FormData();

  //     formdata.append('page',currentPage);
  //     formdata.append('row_count','8');
  //     formdata.append('tag_id',tagId);

  //     await ApiService(url.tag_post,formdata,'POST').then((response) => {
  //         if(response.code === 200){
  //             return {
  //                 data: response.data,
  //                 pagination: response.pagination
  //             };
  //         }else{

  //         }
  //     })
  // }

  return (
    <>
      <div className="container-fluid">
        <div className="container px-0 py-3">
          <div className="row tagDiv">
            <div className="col-lg-8 col-12 col-md-10 col-centered">
              {post &&
                post.map((filterPost, filterPostIndex) => (
                  <ProductPost post={filterPost} key={filterPostIndex} />
                ))}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container">
                <div className="d-flex justify-content-center my-3">
                    <FallbackPaginator
                        data={filter}
                        loading={loading}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        paginate={paginate}
                    />
                </div>
            </div> */}
    </>
  );
};

export default Post;
