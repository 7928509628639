import React from 'react';
import Pagination from 'react-js-pagination';

const PaginationTest = (props) => {
  const { rowCount, totalRecordCount, pageIndex, setPageIndex } = props;

  return (
    <>
      <div>
        <span className="paginationNum">
          <Pagination
            activePage={pageIndex}
            itemsCountPerPage={rowCount}
            totalItemsCount={totalRecordCount}
            pageRangeDisplayed={5}
            onChange={(numPage) => setPageIndex(numPage)}
            nextPageText={'>'}
            prevPageText={'<'}
            // firstPageText={'First'}
            // lastPageText={'Last'}
          />
        </span>
      </div>
    </>
  );
};

export default PaginationTest;
