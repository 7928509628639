import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ApiService } from '../network/service';
import url from '../network/url';
// import Loading from '../components/Loading';
import BreadCrumb from '../common/BreadCrumb';
import NotFound from '../components/NotFound';
// import { convertBlobToBase64 } from '../utils/index'
// import Navigation from '../components/Navigation';
import PdfViewer from '../common/PdfViewer'
import { RiDownloadCloud2Fill } from 'react-icons/ri'
import { useNavigate } from "react-router-dom";

const ProductFlyer = () => {
  const { productId } = useParams();
  let location = useLocation()

  const [product, setProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [download, setDownload] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const region = location?.search?.split("?region=")[1];

    if(!region) {
      navigate('/not-found')
    }

    if(productId && region) {
      console.log('product flyer page::', region)
      // navigate(`/products/${productId}/flyer?region=MM`)
      fetchFlyer(productId, region);
    }
  }, [productId, location?.search])

  const fetchFlyer = async (productId, saleRegion) => {
    setLoading(true);
    const formdata = new FormData();

    formdata.append('product_id', productId);
    formdata.append('sale_region', saleRegion);

    await ApiService(url.flyer, formdata, 'POST').then((response) => {
      console.log('product flyer response::', response)
      if (response.code === 200) {
        setProduct(response.data);
        setLoading(false);
      } else {
        setError('Product not found.');
        setLoading(false);
      }
    });
  };

  const breadcrumb = [
    {
      path: `/products/${productId}`,
      name: `${product.product_name}`
    }
  ];

  return (
    <div>
        {!loading && product && (
          <div className="">
            <div className="">
              <div className="bread-crumb-container mb-4 d-flex align-items-center justify-content-between p-2">
                <BreadCrumb routeList={breadcrumb} />
                {/* <div className="download"> */}
                  {/* <button onClick={handleDownloadPdf}>
                    {
                      download ? (
                        <span className="spinner-border spinner-grow-sm me-1" />
                      ) : (
                        <RiDownloadCloud2Fill size={20} />
                      )
                    }
                  </button> */}
                  <div className="download">
                    <a href={product?.pdf}>
                      {
                        download ? (
                          <span className="spinner-border spinner-grow-sm me-1" />
                        ) : (
                          <RiDownloadCloud2Fill size={20} />
                        )
                      }
                    </a>
                  </div>
                {/* </div> */}
              </div>
            </div>
            <section className="pdf_view_container">
              <PdfViewer url={product?.pdf} />
            </section>
        </div>
      )}
      {error && <NotFound />}
    </div>
  );
};

export default ProductFlyer;


