import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const BreadCrumb = ({routeList}) => {

    const location = useLocation();


  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb breadcrumb-list">
        { 
          routeList.map((route, routeIndex) => 
            <li className={location.pathname === route.path ? "breadcrumb-item active" : "breadcrumb-item"} aria-current={route.name} key={routeIndex}>
              <Link to={{ pathname: route.path }}>
                {route.name}
              </Link>
            </li>
          )
        }
      </ol>
    </nav>
  )
}

export default BreadCrumb