import React, { useState, useEffect } from 'react';
import Agent from '../components/Agent';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import FallbackPaginator from '../components/FallbackPaginator';
import { NavSearchBox } from '../components/NavSearchBox';
import DropDown from '../common/DropDown';
import AgentHeader from '../common/AgentHeader';

const AgentPage = (props) => {
  const [agent, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [paginate, setPaginate] = useState();
  const [group, setGroup] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [chosenGroup, setChosenGroup] = useState('All');

  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  const getAgentGroup = (groupInfo) => {
    setChosenGroup(groupInfo.name);
    setGroup(groupInfo.key);
  };

  const groupTypeList = [
    {
      name: 'All',
      key: ''
    },
    {
      name: 'Group - A',
      key: 'A'
    },
    {
      name: 'Group - B',
      key: 'B'
    },
    {
      name: 'Group - C',
      key: 'C'
    },
    {
      name: 'Group - D',
      key: 'D'
    },
    {
      name: 'Group - E',
      key: 'E'
    },
    {
      name: 'Group - F',
      key: 'F'
    }
  ];

  // const groupColor = {
  //   "A" : "#173F5F",
  //   "B" : "#20639B",
  //   "C" : "#3CAEA3",
  //   "D" : "#F6D55C",
  //   "E" : "#ED553B",
  //   "F" : "#FFD9C9"
  // }
  // const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    getAgentList(searchKeyword, pageIndex, group);
  }, [pageIndex, group, searchKeyword]);

  const getAgentList = async (keyword, currentPage, groupName) => {
    setSearchLoading(true);
    let formdata = new FormData();

    formdata.append('page', currentPage);
    formdata.append('row_count', '20');
    formdata.append('keyword', keyword);
    formdata.append('group', groupName);

    await ApiService(url.agent, formdata, 'POST').then((response) => {
      setSearchLoading(false);
      if (response.code === 200) {
        setAgent(response.data);
        setPaginate(response.pagination);
      }
    });
  };

  return (
    <>
      <div className="pb-3">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <div className="" style={{ marginTop: '5em' }}>
            <AgentHeader
              title="Meet Our Agents"
              description="Agent user can analyze the detail of each sale and purchase operation easily."
            />
            <div className="py-3" style={{ backgroundColor: '#F5F7FB' }}>
              <div className="container p-6 mt-4">
                {/* <div className=' d-flex justify-content-between mt-3 mb-3'>
                      <div className='fb-link-search-box d-inline'>
                          <NavSearchBox name='Agent' searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword}  />
                      </div>
                      <DropDown selectedValue ={chosenGroup} dropDownList={groupTypeList} onChoose={(chosenValue) => {getAgentGroup(chosenValue)}} />
                    </div> */}
                <div className="mt-3 mb-3 filter-box">
                  <div className="d-flex justify-content-center fb-link-search-box d-inline">
                    <NavSearchBox
                      name="Agent"
                      searchKeyword={searchKeyword}
                      setSearchKeyword={setSearchKeyword}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <DropDown
                      selectedValue={chosenGroup}
                      dropDownList={groupTypeList}
                      onChoose={(chosenValue) => {
                        getAgentGroup(chosenValue);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  {searchLoading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: 'calc(100vh - 90px)' }}
                    >
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <Agent
                        data={agent}
                        loading={searchLoading}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        paginate={paginate}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="d-flex justify-content-center my-3">
                <FallbackPaginator
                  data={agent}
                  loading={loading}
                  pageIndex={pageIndex}
                  setPageIndex={setPageIndex}
                  paginate={paginate}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AgentPage;
