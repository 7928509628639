import React, { useState, useEffect } from 'react';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import Post from '../components/Post';
import InfiniteScroll from 'react-infinite-scroll-component';

const PostPage = () => {
  const [post, setPost] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPostList();
  }, []);

  useEffect(() => {
    if (pageIndex > 1) {
      getPostList(pageIndex);
    }
  }, [pageIndex]);

  const getPostList = async (currentPage, searchName) => {
    setSearchLoading(true);
    let formdata = new FormData();

    formdata.append('page', currentPage ?? 1);
    formdata.append('row_count', '10');
    formdata.append('keyword', '');
    formdata.append('status', 'PUBLISH');

    await ApiService(url.post, formdata, 'POST').then((response) => {
      setSearchLoading(false);
      if (response.code === 200) {
        setPost((prevPost) => prevPost.concat(response.data));
      } else {
      }
    });
  };

  const fetchMorePost = () => {
    console.log('page index::', pageIndex);
    setPageIndex((prevPage) => prevPage + 1);
  };

  // const searchPost = (keyword) => {
  //   setSearchKeyword(keyword);
  // }

  return (
    <>
      {/* <Navigation searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} /> */}
      <div className="pb-3" style={{ marginTop: '4.5em' }}>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: 'calc(100vh - 70px)' }}
          >
            <Loading />
          </div>
        ) : (
          <>
            {/* <div className='container py-3'>
                      <div className='col-lg-4 centered'>
                        <NavSearchBox name="Posts" onSearch={searchPost} searchKeyword={searchKeyword} />
                      </div>
                      
                    </div> */}
            <InfiniteScroll
              dataLength={post.length}
              next={fetchMorePost}
              hasMore={post?.length >= 10}
              loader={
                <div className="position-absolute start-50">
                  <Loading height={'30px'} mTop={'auto'} />
                </div>
              }
            >
              <Post
                post={post}
                // tags={tags}
                loading={searchLoading}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
              />
            </InfiniteScroll>
          </>
        )}
      </div>
    </>
  );
};

export default PostPage;
