import { Player } from '@lottiefiles/react-lottie-player';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
      <Player
        src={'https://assets5.lottiefiles.com/packages/lf20_830fdC.json'}
        className="player"
        loop
        autoplay
        style={{ width: '500px', height: '300px' }}
      />
      <h2 className="notfound-404 justify-content-center">
        No content available!
      </h2>
      <Link to={'/'}>
        <button className="notfound-home-button justify-content-center">
          Go To Home Page
        </button>
      </Link>
    </div>
  );
};

export default NotFound;
