import { React } from 'react';
// import { Image } from 'react-bootstrap';
import AgentCard from '../common/AgentCard';

const Agent = (props) => {
  const { data } = props;

  return (
    <>
      <div className="container">
        <div className="row">
          {data &&
            data.map((dat) => (
              <>
                <AgentCard agentInfo={dat} key={dat.id} />
              </>
            ))}
        </div>
      </div>
    </>
  );
};

Agent.defaultProps = {
  loading: false,
  data: []
};

export default Agent;
