import React from 'react';
import TopAgentCard from '../common/TopAgentCard';

const TopAgent = (props) => {
  const { data } = props;

  return (
    <>
      <div className="container mb-5">
        <div className="row justify-content-center">
          {data &&
            data.map((dat, index) => (
              <TopAgentCard agentInfo={dat} key={index} />
            ))}
        </div>
      </div>
    </>
  );
};

TopAgent.defaultProps = {
  data: []
};
export default TopAgent;
