import React from 'react'
import FallbackImage from '../components/FallbackImage'

const CarouselSlider = ({ sliderInfo }) => {

  return (
    <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
        <div className="carousel-indicators">
            {
                sliderInfo.map((_, productAdIndex) =>
                    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to={productAdIndex} className={productAdIndex === 0 ? "active" : ""} aria-label={`Slide ${productAdIndex + 1}`} key={productAdIndex} />
                )
            }
        </div>
        <div className="carousel-inner">
            {
                sliderInfo.map((productAd, productAdIndex) =>
                    <div className={productAdIndex === 0 ? "carousel-item active" : "carousel-item"} data-bs-interval="2000" key={productAdIndex}>
                        <FallbackImage path={productAd?.image?.path} className={"d-block w-100 product-slider"} title="..."  />
                    </div>
                )
            }
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
            <i className="fa-solid fa-chevron-left fa-lg" aria-hidden="true" style={{ color: '#ae69f0' }}></i>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
            <i className="fa-solid fa-chevron-right fa-lg" aria-hidden="true" style={{ color: '#ae69f0' }}></i>
        </button>   
    </div>
  )
}

export default CarouselSlider