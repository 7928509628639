import * as React from 'react';

const Loading = ({ height, mTop }) => {
  return (
    <>
      <svg
        className="loading"
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={50}
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        // {...props}
      >
        <circle cx={30} cy={50} fill="#ead6ee" r={20}>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          />
        </circle>
        <circle cx={70} cy={50} fill="#a0f1ea" r={20}>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="0s"
          />
        </circle>
        <circle cx={30} cy={50} fill="#ead6ee" r={20}>
          <animate
            attributeName="cx"
            repeatCount="indefinite"
            dur="1s"
            keyTimes="0;0.5;1"
            values="30;70;30"
            begin="-0.5s"
          />
          <animate
            attributeName="fill-opacity"
            values="0;0;1;1"
            calcMode="discrete"
            keyTimes="0;0.499;0.5;1"
            dur="1s"
            repeatCount="indefinite"
          />
        </circle>
      </svg>
    </>
  );
};

export default Loading;
