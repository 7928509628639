import React, { useState } from 'react';
import * as dayjs from 'dayjs';
import FallbackImage from '../components/FallbackImage';
import Icon from '../common/Icon';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { postedAgoDate } from '../utils';

const ProductPost = ({ post }) => {
  const {
    profile_picture,
    agent_name,
    description,
    status,
    images,
    created_at,
    tags,
    id
  } = post;

  const [showMorePostDescription, setShowMorePostDescription] = useState(false);

  const [postedInterval, setPostedInterval] = useState(0);

  const postedDate = dayjs(created_at);

  const currentDate = dayjs(new Date());

  // const intervalDate = (today, postDate) => {
  //  let timeDiff =  today.diff(postDate, 'months');
  //  let diffType = timeDiff === 0 ? 'day' : today.diff(postDate, 'month') >= 12 ? 'year' : 'month';
  //  const totalDiff = today.diff(postDate, diffType)
  //  return totalDiff + ` ${totalDiff > 1 ? diffType + 's' : diffType}`
  // }

  useEffect(() => {
    const date = postedAgoDate(postedDate);
    setPostedInterval(date);
  }, [currentDate, postedDate]);

  const postStatus = {
    PUBLISH: <Icon name="globe" />
  };

  return (
    <>
      <Link to={{ pathname: `/posts/${id}` }}>
        <div className="card mb-4 rounded">
          <div className="card-header post-card-header">
            <div className="row row-cols-lg-2 g-2 g-lg-3">
              <div className="col-6 post-profile-column">
                <FallbackImage
                  path={profile_picture}
                  title={'...'}
                  className="product-post-profile"
                />
              </div>
              <div className="col">
                <h5 className="post-owner-name">{agent_name}</h5>
                <span className="post-status" title={`Post Status : ${status}`}>
                  {postStatus[status]} {postedInterval}
                </span>
              </div>
            </div>
          </div>
          <div className="card-body overflow-hidden position-relative post-card-img-container">
            {images?.length > 0 && images[0]?.path !== null ? (
              <div
                key={1}
                style={{ backgroundImage: `url(${images[0].path})` }}
                className="post-card-img"
              />
            ) : (
              <div
                key={1}
                style={{
                  backgroundImage: `url(${'https://i.ibb.co/WcYXrpt/thumbnail.png'})`
                }}
                className="post-card-img"
              />
            )}
            {tags?.length > 0 && (
              <div className="position-absolute post-tag-container">
                <div className="post-tag-info">
                  <Icon name="tag" />
                  &nbsp;
                  <span className="post-tag-info-name">{tags[0]?.name}</span>
                </div>
              </div>
            )}
          </div>
          <div className="card-footer">
            <div className="d-inline">
              <p
                className="post-card-description"
                style={{ width: 'fit-content' }}
              >
                {description.length >= 350 &&
                  description.substring(0, 350) + '...'}
                {showMorePostDescription || description.length < 350
                  ? description
                  : ''}
                {description.length >= 350 ? (
                  <button
                    className="see-more-post-btn"
                    onClick={() => {
                      setShowMorePostDescription((prevInfo) => !prevInfo);
                    }}
                  >
                    {showMorePostDescription ? 'See Less' : 'See More'}
                  </button>
                ) : null}
              </p>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProductPost;
