import React from 'react';

const About = () => {
  return (
    <>
      {/* <Navigation /> */}
      <div
        className="container-fluid"
        style={{ height: 'calc(100vh - 120px)', marginTop: '5em' }}
      >
        <div className="row">
          <div className="col-lg-4 col-md-2 col-sm-12"></div>
          <div className="col-lg-4 col-md-8 col-sm-12">
            <div className="aboutTitle my-1">
              <p style={{ marginTop: '10px' }}>Our Mission</p>
            </div>
            <div className="aboutBody mb-3 p-2 shadow-sm">
              <p className="text-center">
                To provide safe and effective products through our agents to
                community with low cost, effective and innovative marketing
                system.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-2 col-sm-12"></div>
          <div className="col-lg-4 col-md-8 col-sm-12">
            <div className="aboutTitle my-1">
              <p style={{ marginTop: '10px' }}>Our Vision</p>
            </div>
            <div className="aboutBody mb-3 p-2  shadow-sm">
              <p className="text-center">
                To become most powerful and reliable marketing network in
                Myanmar in 5 years with responsible work force taking the
                benefit of modern technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
