import React from 'react';
import PostPage from '../pages/PostPage';

const Tag = () => {
  return (
    <>
      <PostPage />
    </>
  );
};

export default Tag;
