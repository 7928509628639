import * as dayjs from 'dayjs';

/**
 * Deplay debounce
 * @param {func, timer}
 * @returns {func}
 */
export const debounce = (func, milliseconds = 2000) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, milliseconds);
  };
};

/**
 * show posted ago
 * @param {date}
 * @returns {value}
 */
export const postedAgoDate = (date) => {
  const postedDate = dayjs(date);
  const dateDiff = dayjs(new Date()).diff(postedDate, 'day');
  if (dateDiff > 0) {
    return postedDate.format('YYYY-MM-DD');
  }

  // fromNow method need to extend relativeTime plugin
  var relativeTime = require('dayjs/plugin/relativeTime');
  dayjs.extend(relativeTime);

  //to customize a day, a month, a year ago to 1day, 1 month, 1 year
  var updateLocale = require('dayjs/plugin/updateLocale');
  dayjs.extend(updateLocale);
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'a few seconds',
      m: '1 minute',
      mm: '%d minutes',
      h: '1 hour',
      hh: '%d hours',
      d: '1 day',
      dd: '%d days',
      M: '1 month',
      MM: '%d months',
      y: '1 year',
      yy: '%d years'
    }
  });

  return dayjs(postedDate.format()).fromNow();
};

/**
 * Convert blob to file type
 * @param {obj} blob
 * @param {string} fileName
 * @returns {file}
 */
export const convertBlobToBase64 = async (image, fileName, fileType) => {
  const data = await fetch(image);
  const blob = await data.blob();
  const file = new File([blob], fileName, {
    type: fileType,
    lastModified: new Date()
  });

  return file;
};
