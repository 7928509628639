import React from 'react'
import { TypeAnimation } from 'react-type-animation';


const AgentHeader = ({ title, description }) => {
  return (
    <>
     
        <h2 className="text-center">{title}</h2>
        <p className="text-center fst-italic my-4">
         <TypeAnimation
          sequence={[
            description,
        1000, 
        "They play a significant role in self-expression, self-esteem, and cultural practices.",
        1000,
        // wait 1s before replacing "Mice" with "Hamsters"
      
      ]}
      wrapper="span"
      speed={50}
      style={{ fontSize: '1rem', display: 'inline-block' }}
      repeat={Infinity}
    />
      </p>
    </>
  )
}

export default AgentHeader