/* eslint-disable import/no-anonymous-default-export */

let domain = 'https://api.focusbeauty.net';
// let version = '1';

export default {
  product: `${domain}/api/products/list`,
  product_detail: `${domain}/api/products/detail`,
  product_ad: `${domain}/api/advertisements/list`,
  product_review: `${domain}/api/products/reviews/list`,
  product_post: `${domain}/api/products/posts/list`,
  product_attribute: `${domain}/api/products/attributes/list`,
  trending_product: `${domain}/api/products/trendings/list`,
  brand: `${domain}/api/brands/list`,
  brand_product: `${domain}/api/brands/detail/products/list`,
  agent: `${domain}/api/agents/list`,
  top_agent: `${domain}/api/agents/top-agents/list`,
  agent_detail: `${domain}/api/agents/detail`,
  top_agent_detail: `${domain}/api/top-agents/detail`,
  post: `${domain}/api/posts/list`,
  post_detail: `${domain}/api/posts/detail`,
  agent_post: `${domain}/api/agents/posts/list`,
  top_agent_post: `${domain}/api/top-agents/posts/list`,
  tag: `${domain}/api/tags/list`,
  tag_post: `${domain}/api/tags/detail/posts/list`,
  flyer: `${domain}/api/products/flyers/detail`
};
